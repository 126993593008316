{
  "daytime": {
    "earlier": "Earlier",
    "later": "Later"
  },
  "login": {
    "title": "Se connecter",
    "text": "Connectez-vous à votre compte",
    "button": "Se connecter",
    "stressLevel": "Stress Level",
    "errorDialog": {
      "message": "Identifiant ou mot de passe incorrect",
      "accept": "Ok"
    },
    "placeholder": {
      "username": "Veuillez entrer votre identifiant",
      "password": "Veuillez saisir votre mot de passe"
    }
  },
  "sidebar": {
    "bulletins": "Avalanche.report",
    "dangerSources": "Danger Sources",
    "observations": "Observations",
    "qfa": "QFA",
    "modelling": "Modelling",
    "modellingForecast": "Forecast",
    "modellingZamg": "ZAMG multi-modèle",
    "modellingZamgECMWF": "ZAMG ECMWF-EPS",
    "modellingZamgCLAEF": "ZAMG CLAEF-EPS",
    "modellingZamgMeteogram": "GeoSphere meteogram",
    "modellingSnowpack": "SNOWPACK model",
    "modellingSnowpackMeteo": "SNOWPACK modelled meteo",
    "weatherbox": "Weatherbox",
    "awsome": "AWSOME",
    "snowpack": "SNOWPACK",
    "geosphere": "GeoSphere"
  },
  "menu": {
    "logout": "Se déconnecter",
    "language": "Langue",
    "en": "Anglais",
    "de": "Allemand",
    "fr": "Français",
    "it": "Italien",
    "es": "Espagnol",
    "ca": "Catalan",
    "oc": "Aranais",
    "fullscreen": "Fullscreen",
    "settings": "Paramètres",
    "statistics": "Statistiques",
    "admin": "Admin",
    "region": "Région",
    "education": "Pédagogie"
  },
  "matrix": {
    "label": {
      "snowpackStability": "Snowpack Stability",
      "frequency": "Frequency",
      "avalancheSize": "Avalanche Size",
      "dangerRating": "Degré de danger ",
      "override": "(override danger rating)",
      "matrix": "Matrix",
      "referToFairStability": "Refer to fair stability",
      "referToPoorStability": "Refer to poor stability",
      "dangerLevel1": "Danger level 1 (Low)"
    }
  },
  "dangerSources": {
    "status": {
      "missing": "manquant",
      "available": "available",
      "forecast": "forecast",
      "analysis": "analysis",
      "loading": "Loading danger sources ...",
      "saveError": "Danger source could not be saved!",
      "loadDangerSourcesError": "Danger sources could not be loaded!",
      "loadVariantsError": "Danger source variants could not be loaded!"
    },
    "variantStatus": {
      "active": "active",
      "dormant": "dormant",
      "inactive": "inactive"
    },
    "tooltip": {
      "read": "Read danger sources",
      "edit": "Edit danger sources"
    },
    "table": {
      "loadMore": "Load {{count}} more days",
      "title": {
        "forecast": "Forecast",
        "analysis": "Analysis"
      }
    },
    "create": {
      "variantStatus": "Status",
      "variantEditable": "This variant can be edited.",
      "variantNotEditable": "This variant can not be edited.",
      "noVariantsForSelectedDay": "No variants defined for this date.",
      "tooltip": {
        "editDangerSource": "Edit danger source",
        "loadVariants": "Load variants from the day before",
        "edit": "Edit micro regions",
        "copy": "Copy variant",
        "delete": "Delete variant",
        "compare": "Compare variant",
        "forecast": "Compare with forecast"
      },
      "createDangerSource": "Create danger source",
      "createVariant": "Create new variant",
      "noDangerSources": "No danger sources",
      "title": {
        "avalanche": "Avalanche",
        "matrix": "Matrix",
        "characteristics": "Caractéristiques"
      },
      "label": {
        "glidingSnowActivity": "Gliding snow activity",
        "snowHeight": "Snow height",
        "zeroDegreeIsotherm": "0°C isotherm",
        "looseSnowMoisture": "Loose snow moisture",
        "looseSnowGrainShape": "Loose snow grain shape",
        "slabGrainShape": "Slab grain shape",
        "slabThickness": "Slab thickness",
        "slabHandHardness": "Slab hand hardness",
        "slabHardnessProfile": "Slab hardness",
        "slabEnergyTransferPotential": "Slab energy transfer potential",
        "slabDistribution": "Slab distribution",
        "weakLayerGrainShape": "Weak layer grain shape",
        "weakLayerGrainSize": "Weak layer grain size",
        "weakLayerPersistent": "Weak layer persistent",
        "weakLayerThickness": "Weak layer thickness",
        "weakLayerStrength": "Weak layer strength",
        "weakLayerWet": "Weak layer wet",
        "weakLayerCrustAbove": "Weak layer crust above",
        "weakLayerCrustBelow": "Weak layer crust below",
        "weakLayerPosition": "Weak layer position",
        "weakLayerCreation": "Weak layer creation",
        "weakLayerDistribution": "Weak layer distribution",
        "dangerSpotRecognizability": "Danger spot recognizability",
        "remoteTriggering": "Remote triggering",
        "hasDaytimeDependency": "Dépendance diurne",
        "dangerIncreaseWithElevation": "Danger increase with elevation",
        "runoutIntoGreen": "Runout into green",
        "highestDangerAspect": "Highest danger aspect",
        "dangerPeak": "Danger peak",
        "slopeGradient": "Pente",
        "naturalRelease": "Natural release",
        "terrainTypes": "Terrain types",
        "dangerSigns": "Danger signs",
        "aspects": "Expositions"
      },
      "editDangerSourceDialog": {
        "save": "Sauvegarder",
        "cancel": "Annuler"
      }
    }
  },
  "avalancheType": {
    "title": "Type d'avalanche",
    "label": {
      "slab": "SLAB",
      "loose": "LOOSE",
      "glide": "GLIDE"
    },
    "tooltip": {
      "slab": "Slab avalanche",
      "loose": "Loose snow avalanche",
      "glide": "Glide snow avalanche"
    }
  },
  "elevation": {
    "title": "Altitude",
    "high": "Au-dessous",
    "low": "Au-dessus",
    "treeline": "Limite de la forêt"
  },
  "bulletins": {
    "title": "Avalanche.report",
    "tooltip": {
      "edit": "Edit bulletin",
      "read": "Read bulletin",
      "copy": "Copier le bulletin",
      "paste": "Coller le bulletin",
      "cancel": "Cancel copy",
      "update": "Editer",
      "submit": "Submit",
      "submitUpdate": "Submit update",
      "publishChange": "Publish now (no messages)",
      "publishUpdate": "Publish now",
      "caaml": "Afficher CAAML",
      "json": "Afficher JSON",
      "create": "Créer le bulletin",
      "preview": "Aperçu du bulletin (PDF)",
      "check": "Vérifier le bulletin",
      "info": "Afficher l'état de la publication",
      "publishAll": "Publier toutes les régions",
      "mediaFile": "Upload media file"
    },
    "status": {
      "submittedInfo": "Will be published at 5PM",
      "resubmittedInfo": "Will be published at 8AM",
      "publishedInfo" : "Has been published",
      "noPublicationInfo" : "Will NOT be published automatically",
      "loadingPreview": "Créer un aperçu PDF",
      "loading": "Loading bulletin ...",
      "loadMoreBulletins": "Load {{count}} more bulletins",
      "publishing": "Publishing bulletin ...",
      "submitting": "Submitting bulletin ...",
      "saveError": "Bulletin could not be saved!",
      "loadBulletinsError": "Bulletins could not be loaded!"
    },
    "create": {
      "tooltip": {
        "edit": "Edit micro regions",
        "copy": "Copier la région",
        "delete": "Effacer la région",
        "undo": "Undo last change in region",
        "redo": "Redo change in region",
        "createRegion": "Create region",
        "cancel": "Annuler",
        "acceptSuggestion": "Accepter les régions proposées",
        "rejectSuggestion": "Refuser les régions proposées",
        "saveChanges": "Sauvegarder les modifications",
        "discardChanges": "Abandoner les modifications",
        "incompleteAvalancheProblem": "Situation avalancheuse typique incomplet",
        "saveBulletin": "Enregistrer le bulletin",
        "discardBulletin": "Abandoner les modifications de bulletin",
        "loadBulletin": "Téléchargement du bulletin de la veille",
        "uploadJsonBulletin": "Mettre en ligne au format JSON",
        "downloadJsonBulletin": "Télécharger au format JSON",
        "daytimeDependency": "Dépendance diurne",
        "treeline": "Limite de la forêt",
        "tendency": {
          "decreasing": "Le Danger d'avalanche diminue",
          "steady": "Le danger d'avalanche reste le même",
          "increasing": "Le danger d'avalanche augmente",
          "danger": "Avalanche danger"
        },
        "textcat": {
          "edit": "Éditer",
          "copy": "Copier",
          "paste": "Coller",
          "example": "Télécharger exemple de texte",
          "delete": "Effacer"
        },
        "moveUpAvalancheProblem": "Déplacer situation avalancheuse typique en haut",
        "moveDownAvalancheProblem": "Déplacer situation avalancheuse typique en bas",
        "dangerRatingDirectionUp": "Upwards",
        "dangerRatingDirectionDown": "Downwards"
      },
      "save": "Sauvegarder",
      "discard": "Abandoner",
      "back": "Retour",
      "createAggregatedRegion": "Nouvelle région",
      "pasteAggregatedRegion": "Coller région",
      "pendingSuggestions": "Suggestions",
      "rejectSuggestion": "Reject",
      "acceptSuggestion": "Accept",
      "cancelCopyBulletin": "Annuler",
      "createAvalancheProblem": "Situation avalancheuse typique nouvelle",
      "ownRegions": "Mes régions",
      "foreignRegions": "Régions étrangères",
      "externalRegions": "External regions",
      "mapSelectRegion": "Select regions on the map.",
      "statusHeader": "Status of the bulletin",
      "suggestions": "Suggestions",
      "noBulletinsForSelectedDay": "No regions defined for this date.",
      "autosave": "Saved automatically",
      "regionEditable": "This region can be edited.",
      "regionNotEditable": "This region can not be edited.",
      "regionLocked": "This region is locked.",
      "changeDangerRatingElevation": "Extend the danger level",
      "mapTop": "Map above",
      "mapLeft": "Map aside",
      "showNotes": "Show notes",
      "hideNotes": "Hide notes",
      "incompleteAvalancheProblem": "Incomplete",
      "label": {
        "avalancheProblems": "Situation avalancheuse typique",
        "aspects": "Expositions",
        "highlights": "Alerte particulière ",
        "avActivityHighlights": "Titre situation danger d'avalanche",
        "avActivityComment": "Déscription du danger d'avalanche",
        "snowpackStructureComment": "Déscription manteau neigeux",
        "dangerPatterns": "Modèles de danger",
        "tendencyComment": "Déscription de la tendance",
        "weatherComment": "Description of weather",
        "notes": "Notes"
      },
      "copyRegion": {
        "label": "Copy warning region to another bulletin",
        "select": "Paste into ...",
        "currentBulletin": "This bulletin"
      },
      "title": {
        "avalancheProblem": "Avalanche problems",
        "dangerDescription": "Déscription du danger d'avalanche",
        "forenoon": "Matin",
        "afternoon": "Après-midi",
        "snowpackStructure": "Structure du manteau neigeux",
        "tendency": "Tendance",
        "weather": "Weather",
        "showTranslations": "Afficher traductions",
        "hideTranslations": "Masquer traductions"
      },
      "placeholder": {
        "notes": "Écrivez ici ..."
      },
      "pmDialog": {
        "noIFrameSupport": "Votre navigateur ne supporte pas les IFrames !"
      },
      "discardDialog": {
        "message": "Abandoner les modifications?",
        "accept": "Oui",
        "reject": "Non"
      },
      "deleteAggregatedRegionDialog": {
        "message": "Effacer la région ?",
        "accept": "Oui",
        "reject": "Non"
      },
      "noRegionDialog": {
        "message": "Au moins une région doit être la tien.",
        "accept": "Ok"
      },
      "loadDialog": {
        "message": "Télécharger le bulletin de la veille?",
        "accept": "Oui",
        "reject": "Non"
      },
      "loadAutoSaveDialog": {
        "message": "Télécharger le bulletin sauvegardé automatiquement?",
        "accept": "Oui",
        "reject": "Non"
      },
      "saveErrorDialog": {
        "message": "Il y avait une erreur en sauvegardant les bulletins!",
        "accept": "Ok"
      },
      "changeErrorDialog": {
        "message": "Il y avait une erreur dans la modification des bulletins!",
        "accept": "Ok"
      },
      "loadingErrorDialog": {
        "message": "Il y avait une erreur en téléchargant les bulletins!",
        "accept": "Ok"
      },
      "loadingJsonFileErrorDialog": {
        "message": "Erreur de chargement des bulletins du fichier JSON",
        "accept": "Ok"
      },
      "avalancheProblemErrorDialog": {
        "message": "Avalanche problem, aspects, snowpack stability, frequency and/or avalanche size is missing for at least one avalanche problem",
        "accept": "Ok"
      },
      "loadAvActivityCommentExampleTextDialog": {
        "message": "Pour quelle situation souhaitez-vous charger un exemple de texte",
        "newSnow": "Neige fraîche",
        "windSlab": "Wind slab",
        "persistentWeakLayers": "Neige ancienne (Sous-couche fragile persistante)",
        "wetSnow": "Avalanches mouillées (Neige humide)",
        "glidingSnow": "Avalanches de glissement (Avalanches de fond)",
        "favourableSituation": "Situation favorable",
        "cancel": "Annuler"
      },
      "removeDaytimeDependencyDialog" : {
        "message": "Which avalanche problems do you want to keep?",
        "cancel": "Annuler"
      },
      "strategicMindsetDialog" : {
        "title": "Strategic Mindsets",
        "introduction": "The \"Strategic Mindsets\" are intended to express our mental attitude with regard to the avalanche situation and its development in the corresponding warning region. It should not be derived directly from the hazard assessment, but rather understood and used as an intuitive impression of the overall situation. The \"Strategic Mindsets\" are therefore primarily a communication tool. Using the mindsets and associated definitions, we forecasters can quickly and efficiently exchange information about the development of the hazard situation and find a common language for communication.",
        "description": {
          "title": "Description:",
          "text": {
            "assessment": "There is <b>great uncertainty</b> in the risk assessment due to <b>insufficient information</b> from the site.",
            "stepping_out": "<b>Decreasing tendency</b> of the hazard situation (also within a danger level).",
            "status_quo": "Same hazardous situation. <b>No significant change</b> compared to the previous day.",
            "stepping_back": "<b>Increasing tendency</b> of the hazard situation (also within a danger level).",
            "entrenchment": "<b>Almost constant, tense hazardous situation.</b> The main danger comes from (a) <b>persistent weak layer</b>(s) susceptible to interference.",
            "free_ride": "<b>Low avalanche danger</b> with generally stable snow cover. Only small avalanches are possible in extremely steep terrain. ",
            "high_alert": "Exceptional situation with <b>high or very high avalanche danger</b>. Spontaneous avalanches of <b>very large or extreme magnitude</b> are possible",
            "spring_diurnal": "Wet snow avalanches during the course of the day, which are subject to a <b>clear diurnal cycle</b>, are decisive for the risk assessment."
          }
        },
        "communication": {
          "title": "Communication:",
          "text": {
            "assessment": "Avalanche situation must be checked locally. Restraint is recommended.",
            "stepping_out": "Gradual decrease in danger. However, caution is still required and the improving situation should be assessed locally before entering riskier terrain.  ",
            "status_quo": "Hazardous situation is unchanged. Feedback from observers helps to better outline the hazardous situation. More favourable terrain should be communicated with emphasis. ",
            "stepping_back": "Clear communication of the increase in avalanche danger - even within a danger level. Information on the causes supports comprehensibility in communication. ",
            "entrenchment": "Caution and restraint are required. Situation hardly changes. If possible, provide as precise information as possible on distribution and possibility to trigger. Increased communication of observations that reveal the situation and trigger restraint.",
            "free_ride": "Little text. Emphasise the generally favourable overall situation and potentially isolated hazard areas",
            "high_alert": "Communication across all channels. The focus is no longer on winter sports. Danger even in areas that are not usually at risk of avalanches.",
            "spring_diurnal": "Favourable situation in the morning, increasing during the day. Assessment should be made locally by observing the softening of the melt-freeze crust and the increasing soaking of the snow cover."
          }
        },
        "examples": {
          "title": "Examples:",
          "text": {
            "assessment": "Start of the season.",
            "stepping_out": "Cooling leads to a decrease in the risk of wet snow avalanches after a warm and humid weather phase.",
            "status_quo": "Consistent wind-slab problem with cold temperatures, loose snow and wind.",
            "stepping_back": "Abundant fresh snowfall leads to an increase in the danger situation.",
            "entrenchment": "Critical persistent weak layers problem with potential for dangerously large avalanches. The situation is only stabilising very slowly and requires a great deal of restraint.",
            "free_ride": "Low avalanche danger, no persistent weak layers, all terrain has already been groomed.",
            "high_alert": "Danger level 4 or higher due to large amounts of fresh snow and/or problematic, persistent weak layers. ",
            "spring_diurnal": "Spring situation with dry high-pressure weather and moderate temperatures with otherwise mostly stable old snow cover."
          }
        },
        "strategicMindset": {
          "assessment": "High Uncertainty",
          "stepping_out": "Improving",
          "status_quo": "Status Quo",
          "stepping_back": "Deteriorating",
          "entrenchment": "Persistent Unstable",
          "free_ride": "Favorable",
          "high_alert": "High Alert",
          "spring_diurnal": "Spring Diurnal"
        }
      },
      "decisionTree" : {
        "filepath": "./assets/img/decision_tree-en.svg",
        "feedbackURL": "https://models.avalanche.report:8061/en/",
        "decisionTree" : "Decision tree",
        "feedback" : "Feedback",
        "save": "Sauvegarder",
        "discard": "Abandoner"
      }
    },
    "table": {
      "title": {
        "date": "Date",
        "status": {
          "AT-02": "Carinthie",
          "AT-03": "Basse-Autriche",
          "AT-04": "Haute-Autriche",
          "AT-05": "Salzbourg",
          "AT-06": "Styrie",
          "AT-07": "Tyrol",
          "AT-08": "Vorarlberg",
          "CH" : "Suisse",
          "DE-BY" : "Bavière",
          "GEOSPHERE": "GEOSPHERE",
          "IT-32-TN": "Trentin",
          "IT-32-BZ": "Tyrol du Sud",
          "ES-CT-L": "Aran"
        }
      },
      "status": {
        "missing": "manquant",
        "draft": "ébauche",
        "submitted": "soumis",
        "published": "publié",
        "updated": "mis à jour",
        "resubmitted": "soumis à nouveau",
        "republished": "publié à nouveau"
      },
      "publishBulletinsDialog": {
        "message": "Publier le bulletin ?",
        "missingDangerRating": "Degré de danger indéfini",
        "missingRegion": "Régions pas notées",
        "duplicateRegion": "Régions notées plusieurs fois",
        "missingAvActivityHighlights": "Titre situation danger d'avalanche manque",
        "missingAvActivityComment": "Déscription du danger d'avalanche manque",
        "missingSnowpackStructureHighlights": "Titre manteau neigeux manque",
        "missingSnowpackStructureComment": "Déscription manteau neigeux manque",
        "pendingSuggestions": "Propositions en attente",
        "incompleteTranslation": "Traduction incomplet",
        "accept": "Oui",
        "reject": "Non"
      },
      "publicationStatusDialog": {
        "headline": "Publication Status",
        "publishedBy": "Publié par :",
        "publishedAt": "Publié à :",
        "validFor": "Valide pour:",
        "title": {
          "prod": "Serious stuff",
          "test": "Test system",
          "task": "Task",
          "language": {
            "all": "ALL",
            "de": "DE",
            "it": "IT",
            "en": "EN"
          }
        },

        "caaml": {
          "label": "CAAML Files",
          "tooltip": "Create CAAML Files",
          "success": "{{prefix}}CAAML Files successfully created!",
          "error": "{{prefix}}CAAML Files could not be created!"
        },
        "map": {
          "label": "Maps",
          "tooltip": "Create Maps",
          "success": "{{prefix}}Maps successfully created!",
          "error": "{{prefix}}Maps could not be created!"
        },
        "pdf": {
          "label": "PDFs",
          "tooltip": "Create PDFs",
          "success": "{{prefix}}PDFs successfully created!",
          "error": "{{prefix}}PDFs could not be created!"
        },
        "html": {
          "label": "Simple HTMLs",
          "tooltip": "Create Simple HTML Files",
          "success": "{{prefix}}Simple HTML Files successfully created!",
          "error": "{{prefix}}Simple HTML Files could not be created!"
        },
        "email": {
          "label": "Emails",
          "tooltip": "Send Emails",
          "success": "{{prefix}}Emails successfully sent!",
          "error": "{{prefix}}Emails could not be sent!"
        },
        "telegram": {
          "label": "Telegram Messages",
          "tooltip": "Send Telegram Messages",
          "success": "{{prefix}}Telegram Messages successfully sent!",
          "error": "{{prefix}}Telegram Messages could not be sent!"
        },
        "push": {
          "label": "Push Notifications",
          "tooltip": "Send Push Notifications",
          "success": "{{prefix}}Push Notifications successfully sent!",
          "error": "{{prefix}}Push Notifications could not be sent!"
        },
        "accept": "Ok"
      },
      "mediaFileDialog": {
        "headline": "Media File",
        "important": "IMPORTANT",
        "missingFile": "No file selected!",
        "uploadError": "Upload failed!",
        "accept": "Envoyer",
        "reject": "Annuler"
      },
      "publishBulletinsErrorDialog": {
        "message": "Il y avait une erreur dans la publication des bulletins!",
        "accept": "Ok"
      },
      "submitBulletinsDialog": {
        "message": "Soumettre le bulletin?",
        "missingDangerRating": "Degré de danger indéfini",
        "missingRegion": "Régions pas notées",
        "missingAvActivityHighlights": "Titre situation danger d'avalanche",
        "missingAvActivityComment": "Déscription du danger d'avalanche manque",
        "missingSnowpackStructureHighlights": "Titre manteau neigeux manque",
        "missingSnowpackStructureComment": "Déscription manteau neigeux manque",
        "pendingSuggestions": "Propositions en attente",
        "accept": "Oui",
        "reject": "Non"
      },
      "submitBulletinsErrorDialog": {
        "message": "Il y avait une erreur dans la soumission des bulletins!",
        "accept": "Ok"
      },
      "submitBulletinsDuplicateRegionDialog": {
        "message": "Régions notées plusieurs fois! Bulletin ne peut pas être soumis",
        "accept": "Ok"
      },
      "checkBulletinsErrorDialog": {
        "message": "Il y avait une erreur dans la contrôle des bulletins!",
        "accept": "Ok"
      },
      "previewErrorDialog": {
        "message": "Impossible de charger l'aperçu",
        "accept": "Ok"
      },
      "checkBulletinsDialog": {
        "message": "Contrôler le bulletin",
        "missingDangerRating": "Degré de danger indéfini",
        "missingRegion": "Régions pas notées",
        "missingAvActivityHighlights": "Titre situation danger d'avalanche",
        "missingAvActivityComment": "Déscription du danger d'avalanche manque",
        "missingSnowpackStructureHighlights": "Titre manteau neigeux manque",
        "missingSnowpackStructureComment": "Déscription manteau neigeux manque",
        "pendingSuggestions": "Propositions en attente",
        "incompleteTranslation": "Traduction incomplet",
        "ok": "(tout bien)",
        "accept": "Ok"
      },
      "publishAllDialog": {
        "message": "Publier toutes les régions ?",
        "accept": "Oui",
        "reject": "Non"
      }
    },
    "caaml": {
      "title": "CAAML",
      "caamlNotLoadedDialog": {
        "message": "Erreur téléchargement de CAAML!",
        "accept": "Ok"
      },
      "noCaamlDialog": {
        "message": "Aucun bulletin publié n'est disponible",
        "accept": "Ok"
      }
    },
    "json": {
      "title": "JSON",
      "jsonNotLoadedDialog": {
        "message": "Erreur téléchargement de JSON!",
        "accept": "Ok"
      },
      "noJsonDialog": {
        "message": "Aucun bulletin publié n'est disponible",
        "accept": "Ok"
      }
    }
  },
  "training": {
    "training": "Training",
    "timestamp": "Training timestamp"
  },
  "admin": {
    "loadingStatistics": "CSV en cours de création...",
    "server-configuration": {
      "title": "Server",
      "button": {
        "create": "Create server",
        "save": "Sauvegarder"
      },
      "table": {
        "name": "Nom",
        "username": "Username",
        "password": "Mot de passe",
        "publishAt5PM": "Publier à 5PM",
        "publishAt8AM": "Publier à 8AM",
        "pdfDirectory": "Répertoire pour PDF",
        "htmlDirectory": "Répertoire pour HTML",
        "serverImagesUrl": "Chemin vers les images du serveur",
        "mapsPath": "Chemin vers les cartes",
        "mediaPath": "Path to media files",
        "mapProductionUrl": "URL pour la production des cartes",
        "apiUrl": "API URL"
      },
      "success": "Configuration sauvegardée avec succès",
      "error": "Configuration ne pouvait pas être enregistrer"
    },
    "region-configuration": {
      "title": "Région",
      "button": {
        "create": "Create region",
        "save": "Sauvegarder"
      },
      "table": {
        "general": {
          "title": "General",
          "id": "ID",
          "microRegions": "Number of micro regions",
          "subRegions": "Sub regions",
          "superRegions": "Super regions",
          "neighborRegions": "Neighbouring regions"
        },
        "publication": {
          "title": "Publication",
          "publishBulletins": "Publish avalanche forecast",
          "publishBlogs": "Publish blog posts",
          "createCaamlV5": "Create CAAML v5",
          "createCaamlV6": "Create CAAML v6",
          "createJson": "Create JSON",
          "createMaps": "Créer cartes",
          "createPdf": "Créer PDF",
          "sendEmails": "Envoyer les emails",
          "createSimpleHtml": "Creer HTML simple",
          "sendTelegramMessages": "Send telegram messages",
          "sendPushNotifications": "Send push notifications",
          "pdfColor": "PDF color",
          "emailColor": "Email color",
          "pdfMapYAmPm": "Y for PDF map (am/pm)",
          "pdfMapYFd": "Y for PDF map (fd)",
          "pdfMapWidthAmPm": "Map width for PDF (am/pm)",
          "pdfMapWidthFd": "Map width for PDF (fd)",
          "pdfMapHeight": "Map height for PDF",
          "pdfFooterLogo": "Logo for PDF footer",
          "pdfFooterLogoColorPath": "Logo for PDF footer (color)",
          "pdfFooterLogoBwPath": "Logo for PDF footer (bw)",
          "mapXmax": "Map X max",
          "mapXmin": "Map X min",
          "mapYmax": "Map Y max",
          "mapYmin": "Map Y min",
          "simpleHtmlTemplateName": "Simple HTML template",
          "geoDataDirectory": "Geodata directory",
          "mapLogoColorPath": "Logo for map (color)",
          "mapLogoBwPath": "Logo for map (bw)",
          "mapLogoPosition": "Logo position for map",
          "mapCenterLat": "Center position of map (lat)",
          "mapCenterLng": "Center position of map (long)",
          "imageColorbarColorPath": "Colorbar (color)",
          "imageColorbarBwPath": "Colorbar (b/w)"
        },
        "components": {
          "title": "Components",
          "enableDangerSources": "Enable danger sources",
          "enableObservations": "Enable observations",
          "enableModelling": "Enable modelling",
          "enableWeatherbox": "Enable weather box"
        },
        "configuration": {
          "title": "Configuration",
          "showMatrix": "Show matrix",
          "enableMediaFile": "Enable media file",
          "enableStrategicMindset": "Enable strategic mindset",
          "enableStressLevel": "Enable stress level",
          "enableAvalancheProblemCornices": "Enable avalanche problem CORNICES",
          "enableAvalancheProblemNoDistinctAvalancheProblem": "Enable avalanche problem NO DISTINCT AVALANCHE PROBLEM",
          "enableWeatherTextField": "Enable weather text field"
        }
      },
      "success": "Configuration sauvegardée avec succès",
      "error": "Configuration ne pouvait pas être enregistrer"
    },
    "users": {
      "title": "Utilisateurs",
      "button": {
        "create": " Create user"
      },
      "tooltip": {
        "edit": "Editer utilisateur",
        "changePassword": "Modifier le mot de passe",
        "delete": "Supprimer utilisateur"
      },
      "table": {
        "name": "Nom",
        "email": "Email",
        "organization": "Organisation",
        "regions": "Régions",
        "roles": "Fonctions"
      },
      "deleteUserModalDialog": {
        "message": "Voulez-vous supprimer l'utilisateur ?",
        "accept": "Oui",
        "reject": "Non"
      },
      "deleteUser": {
        "success": "Utilisateur supprimé",
        "error": "Impossible de supprimer l'utilisateur"
      },
      "createUser": {
        "success": "Utilisateur créé",
        "error": "Impossible de créer l'utilisateur"
      },
      "updateUser": {
        "success": "Utilisateur mis à jour",
        "error": "Mise à jour utilisateur impossible"
      }
    },
    "createUser": {
      "table": {
        "image": "Image",
        "name": "Nom",
        "email": "Email",
        "organization": "Organisation",
        "password": "Mot de passe",
        "password2": "Répéter le mot de passe",
        "roles": "Fonctions",
        "regions": "Régions"
      },
      "error": {
        "name": {
          "required": "Veuillez saisir un nom"
        },
        "email": {
          "required": "Veuillez saisir une adresse mail",
          "invalid": "Adresse mail invalide"
        },
        "password": {
          "required": "Veuillez saisir le mot de passe",
          "length": "Le mot de passe doit contenir au moins 8 caractères",
          "mismatch": "Les mots de passe ne sont pas identiques"
        }
      },
      "button": {
        "create": "Créer utilisateur",
        "update": "Mettre à jour l'utilisateur",
        "cancel": "Annuler"
      }
    },
    "observations": {
      "table": "Table",
      "map": "Carte",
      "gallery": "Gallery",
      "filters": "Filters",
      "title": "Observations",
      "newObservation": "New observation",
      "exportObservations": "Export observations",
      "toggleFilterBar": "Show/hide filter bar",
      "button": {
        "submit": "Télécharger"
      },
      "label": {
        "dateRange": "Période",
        "observer": "Observateur"
      },
      "selectRegion": {
        "placeholder": "Région",
        "selectionLabel": "{0} region(s) selected"
      },
      "selectSources": {
        "placeholder": "Sources",
        "selectionLabel": "{0} source(s) selected"
      },
      "charts": {
        "charts": "Charts",
        "nan": "Without specification",
        "classify": "Classify",
        "label": "Label",
        "invert": "Invert",
        "reset": "Reset",
        "aspect": {
          "caption": "Exposition"
        },
        "elevation": {
          "caption": "Altitude"
        },
        "stability": {
          "caption": "Stability"
        },
        "observationType": {
          "caption": "Observation Type"
        },
        "importantObservation": {
          "caption": "Important"
        },
        "avalancheProblem": {
          "caption": "Situation avalancheuse typique"
        },
        "dangerPattern": {
          "caption": "Danger Pattern"
        },
        "days": {
          "caption": "Jour"
        }
      }
    },
    "blog": {
      "title": "Blog",
      "language": {
        "title": "Langue",
        "de": "DE",
        "it": "IT",
        "en": "EN"
      },
      "all": {
        "tooltip": "Send Emails, Telegram and Push",
        "success": "{{prefix}}Email, Telegram and Push successfully sent!",
        "error": "{{prefix}}Email, Telegram and Push could not be sent!"
      },
      "email": {
        "tooltip": "Send Emails",
        "success": "{{prefix}}Email successfully sent!",
        "error": "{{prefix}}Email could not be sent!"
      },
      "telegram": {
        "tooltip": "Send via Telegram Channel",
        "success": "{{prefix}}Telegram successfully sent!",
        "error": "{{prefix}}Telegram could not be sent!"
      },
      "push": {
        "tooltip": "Send via Push Notifications",
        "success": "{{prefix}}Push successfully sent!",
        "error": "{{prefix}}Push could not be sent!"
      }
    }
  },
  "settings": {
    "title": "Paramètres",
    "changePassword": {
      "title": "Modifier le mot de passe",
      "oldPassword": {
        "label": "Mot de passe actuel",
        "placeholder": "Mot de passe actuel"
      },
      "newPassword1": {
        "label": "Nouveau mot de passe",
        "placeholder": "Nouveau mot de passe"
      },
      "newPassword2": {
        "label": "Nouveau mot de passe (répéter)",
        "placeholder": "Nouveau mot de passe"
      },
      "submit": "Modifier le mot de passe",
      "passwordChanged": "Mot de passe changé avec succès",
      "passwordChangeError": "Impossible de changer le mot de passe",
      "passwordIncorrect": "Mot de passe actuel incorrect",
      "error": {
        "oldPassword": {
          "required": "Saisissez votre mot de passe actuel"
        },
        "newPassword": {
          "required": "Saisissez votre nouveau mot de passe ",
          "mismatch": "Les mots de passe ne sont pas identiques"
        }
      }
    },
    "display": {
      "title": "Afficher",
      "table": {
        "showCaaml": "Afficher CAAML",
        "showJson": "Afficher JSON"
      }
    }
  },
  "statistics": {
    "title": "Statistiques",
    "button": {
      "submit": "Télécharger"
    },
    "label": {
      "dateRange": "Période",
      "extended": "Extended",
      "duplicates": "Doublons"
    }
  },

  "dangerRating": {
    "noRating": "pas noté",
    "low": "faible",
    "moderate": "limité",
    "considerable": "marqué",
    "high": "fort",
    "veryHigh": "très fort"
  },
  "avalancheProblem": {
    "newSnow": "Neige fraîche",
    "new_snow": "Neige fraîche",
    "windSlab": "Wind slab",
    "wind_slab": "Wind slab",
    "persistentWeakLayers": "Neige ancienne (Sous-couche fragile persistante)",
    "persistent_weak_layers": "Neige ancienne (Sous-couche fragile persistante)",
    "wetSnow": "Avalanches mouillées (Neige humide)",
    "wet_snow": "Avalanches mouillées (Neige humide)",
    "glidingSnow": "Avalanches de glissement (Avalanches de fond)",
    "gliding_snow": "Avalanches de glissement (Avalanches de fond)",
    "cornices": "Cornices",
    "noDistinctAvalancheProblem": "No distinct problem",
    "no_distinct_problem": "No distinct problem",
    "favourableSituation": "Situation favorable",
    "favourable_situation": "Situation favorable"
  },
  "dangerPattern": {
    "dp1": "md.1: sous-couche fragile persistante",
    "dp2": "md.2: neige glissante",
    "dp3": "md.3: pluie",
    "dp4": "md.4: froid suivant chaud / chaud suivant froid",
    "dp5": "md.5: chute de neige après une longue période de froid",
    "dp6": "md.6: froid, neige légère et vent",
    "dp7": "md.7: zones avec peu de neige à coté des zones enneigées",
    "dp8": "md.8: givre de surface enneigé",
    "dp9": "md.9: neige roulée enneigé",
    "dp10": "md.10: situation de printemps"
  },
  "strategicMindset": {
    "title": "Strategic Mindset",
    "assessment": "High Uncertainty",
    "stepping_out": "Improving",
    "status_quo": "Status Quo",
    "stepping_back": "Deteriorating",
    "entrenchment": "Persistent Unstable",
    "free_ride": "Favorable",
    "high_alert": "High Alert",
    "spring_diurnal": "Spring Diurnal"
  },
  "observationType": {
    "TimeSeries": "Time series",
    "Incident": "Incident",
    "Profile": "Snow profile",
    "Closure": "Closure",
    "Blasting": "Blasting",
    "Avalanche": "Avalanche",
    "Evaluation": "Evaluation",
    "SimpleObservation": "Simple observation",
    "Webcam": "Webcam"
  },
  "importantObservation": {
    "SnowLine": "Snow line",
    "SurfaceHoar": "Surface hoar",
    "Graupel": "Graupel",
    "StabilityTest": "Stability test",
    "IceFormation": "Ice formation",
    "VeryLightNewSnow": "Very light new snow"
  },
  "aspect": {
    "N": "N",
    "NE": "NE",
    "E": "E",
    "SE": "SE",
    "S": "S",
    "SW": "SO",
    "W": "O",
    "NW": "NO"
  },
  "role": {
    "SUPERADMIN": "Super Administrator",
    "ADMIN": "Administrateur",
    "FORECASTER": "Prévisionniste",
    "FOREMAN": "Foreman",
    "OBSERVER": "Observateur"
  },
  "tendency": {
    "decreasing": "Le Danger d'avalanche diminue",
    "steady": "Danger d'avalanche reste la même",
    "increasing": "Le danger d'avalanche augmente"
  },
  "snowpackStability": {
    "unknown": "unknown",
    "good": "good",
    "fair": "fair",
    "poor": "poor",
    "very_poor": "very poor"
  },
  "frequency": {
    "none": "(nearly) none",
    "few": "a few",
    "some": "some",
    "many": "many"
  },
  "avalancheSize": {
    "small": "small",
    "medium": "medium",
    "large": "large",
    "very_large": "very large",
    "extreme": "extreme"
  },
  "complexity": {
    "easy": "Facile",
    "challenging": "Exigeant",
    "complex": "Complexe"
  },
  "glidingSnowActivity": {
    "low": "faible",
    "medium": "medium",
    "high": "fort"
  },
  "wetness": {
    "dry": "dry",
    "moist": "moist",
    "wet": "wet"
  },
  "characteristic": {
    "low": "faible",
    "medium": "medium",
    "high": "fort",
    "very_high": "très fort"
  },
  "thickness": {
    "thin": "thin",
    "thick": "thick"
  },
  "snowpackPosition": {
    "upper": "upper",
    "middle": "middle",
    "lower": "lower",
    "ground": "ground"
  },
  "creationProcess": {
    "radiation_recrystallization": "radiation",
    "diurnal_recrystallization": "diurnal",
    "melt_layer_recrystallization": "melt layer"
  },
  "recognizability": {
    "very_easy": "very easy",
    "easy": "easy",
    "hard": "hard",
    "very_hard": "very hard"
  },
  "distribution": {
    "isolated": "isolated",
    "specific": "specific",
    "widespread": "widespread"
  },
  "probability": {
    "likely": "likely",
    "possible": "possible",
    "unlikely": "unlikely"
  },
  "detailedDaytime": {
    "evening": "evening",
    "first_night_half": "first night half",
    "second_night_half": "second night half",
    "morning": "morning",
    "forenoon": "forenoon",
    "afternoon": "afternoon"
  },
  "slopeGradient": {
    "moderately_steep": "moderately steep",
    "steep": "steep",
    "very_steep": "very steep",
    "extremely_steep": "extremely steep"
  },
  "handHardness": {
    "fist": "F",
    "four_fingers": "4F",
    "one_finger": "1F",
    "pencil": "P",
    "knife": "K",
    "ice": "I"
  },
  "hardnessTendency": {
    "decreasing": "decreasing with height",
    "steady": "uniform",
    "increasing": "increasing with height"
  },
  "terrainType": {
    "gullies": "gullies",
    "bowls": "bowls",
    "pass_areas": "pass areas",
    "ridge_lines": "ridge lines"
  },
  "dangerSign": {
    "shooting_cracks": "shooting cracks",
    "whumpfing": "whumpfing"
  },
  "changeRegionDialog": {
    "message": "Do you want to change the region?",
    "accept": "Oui",
    "reject": "Non"
  },
  "zamg": {
    "table": "Table",
    "map": "Carte",
    "region": "Région",
    "newSnow": "Neige fraîche"
  },
  "snowpack": {
    "plotType": {
      "LWC_stratigraphy": "neige humide: teneur en eau liquide",
      "wet_snow_instability": "neige humide: instabilité de la neige mouillée",
      "Sk38_stratigraphy": "couches fragiles persistantes: SK38 index de stabilité",
      "stratigraphy": "couches fragiles persistantes: stratigraphie de la neige"
    },
    "aspect": {
      "flat": "plat",
      "north": "nord",
      "south": "sud"
    },
    "meteo": {
      "new_snow_plot_3day": "Neige fraîche (3j)",
      "new_snow_plot_7day": "Neige fraîche (7j)",
      "new_snow_plot_1month": "Neige fraîche (31j)",
      "new_snow_plot_season": "Neige fraîche (toute la saison)",
      "new_snow_plot_forecast": "Neige fraîche (prévisons)",
      "wet_snow_plot_3day": "neige humide (3j)",
      "wet_snow_plot_7day": "neige humide (7j)",
      "wet_snow_plot_1month": "neige humide (31j)",
      "wet_snow_plot_season": "neige humide (toute la saison)",
      "wet_snow_plot_forecast": "neige humide (prévisions)",
      "HS_table_24h": "snow height table (1d)",
      "HS_table_72h": "snow height table (3d)",
      "HS_table_season": "snow height table (season)",
      "HS_table_forecast": "snow height table (forecast)",
      "TA_table_24h": "temperature table (1d)",
      "TA_table_72h": "temperature table (3h)",
      "TA_table_season": "temperature table (season)"
    }
  },
  "grainShape": {
    "PP": {
      "code": "PP",
      "class": "Precipitation Particles"
    },
    "PPgp": {
      "code": "PPgp",
      "class": "Graupel"
    },
    "DF": {
      "code": "DF",
      "class": "Decomposing and Fragmented precipitation particles"
    },
    "RG": {
      "code": "RG",
      "class": "Rounded Grains"
    },
    "FC": {
      "code": "FC",
      "class": "Faceted Crystals"
    },
    "DH": {
      "code": "DH",
      "class": "Depth Hoar"
    },
    "SH": {
      "code": "SH",
      "class": "Surface Hoar"
    },
    "MF": {
      "code": "MF",
      "class": "Melt Forms"
    },
    "MFcr": {
      "code": "MFcr",
      "class": "Melt-freeze crust"
    },
    "IF": {
      "code": "IF",
      "class": "Ice Formations"
    }
  },
  "button": {
    "yes": "yes",
    "no": "no"
  },
  "observations": {
    "aspect": "Exposition",
    "authorName": "Nom de l'auteur",
    "avalancheLength": "Longueur d'avalanche",
    "avalancheProblem": "Situation avalancheuse typique",
    "avalancheSize": "Taille d'avalanche",
    "avalancheType": "Type d'avalanche",
    "avalancheWidth": "Avalanche width",
    "search": "Rechercher",
    "count": "# observations:",
    "layers": {
      "observers": "Observers",
      "weatherStations": "Weather stations",
      "webcams": "Webcams",
      "observations": "Observations"
    },
    "weatherStations": {
      "tooltips": {
        "globalRadiation": "Global radiation",
        "snowHeight": "Snow height",
        "snowDifference24h": "Snow difference 24h",
        "snowDifference48h": "Snow difference 48h",
        "snowDifference72h": "Snow difference 72h",
        "airTemperature": "Air temperature",
        "airTemperatureMax": "Air temperature Max",
        "airTemperatureMin": "Air temperature Min",
        "surfaceTemperature": "Surface temperature",
        "surfaceHoar": "Potential surface hoar formation",
        "surfaceHoarCalc": "Potential surface hoar formation (Lehning et. al.)",
        "dewPoint": "Dew point temperature",
        "relativeHumidity": "Relative humidity",
        "windDirection": "Wind direction",
        "windSpeed": "Wind speed",
        "windGust": "Wind gust"
      }
    },
    "button": {
      "add": "Ajouter une observation",
      "save": "Enregistrer l'observation",
      "delete": "Supprimer l'observation",
      "deleteConfirm": "Supprimer l'observation ?",
      "discard": "Abandoner"
    },
    "comment": "Commentaire",
    "content": "Contenu",
    "dangerRating": "Degré de danger",
    "elevation": "Altitude",
    "eventDate": "Date de l'événement",
    "eventType": "Type d'événement",
    "eventTypes": {
      "IMPORTANT": "Événement important",
      "NEIGHBOR_REGION": "Neighbouring region",
      "NORMAL": "Événement normal",
      "PERSON_DEAD": "Personnes emportées (décès)",
      "PERSON_INJURED": "Personnes emportées (blessures)",
      "PERSON_NO": "Pas de personnes emportées",
      "PERSON_UNINJURED": "Personnes emportées (aucun blessé)",
      "PERSON_UNKNOWN": "Event (unknown)",
      "TRAFFIC": "Traffic"
    },
    "fractureDepth": "Épaisseur de la cassure",
    "hasCoordinates": "Has coordinates",
    "incline": "Incline",
    "latitude": "Latitude",
    "locationName": "Localisation",
    "longitude": "Longitude",
    "reportDate": "Date du rapport",
    "showTable": "Table",
    "withoutCoordinates": "Observations without coordinates:",
    "sidebar": {
      "filter": {
        "title": "Filter"
      },
      "legend": {
        "title": "Legend"
      },
      "sources": {
        "title": "Data Sources"
      },
      "views": {
        "title": "Views"
      }
    }
  },
  "units": {
    "cm": "cm",
    "mm": "mm",
    "m": "m"
  },
  "qfa": {
    "day": "Jour",
    "time": "Time",
    "back": "Retour",
    "parameters": {
      "DUST": "sahara dust",
      "N": "total cloud cover in /8",
      "Nh": "high cloud cover in /8",
      "Nm": "medium high cloud cover in /8",
      "Nl": "low cloud cover in /8",
      "N-CU": "cumulus cloud cover in /8",
      "AUSL.T": "trigger temperature",
      "SHW-IX": "Showalter-Index",
      "WX -CUF": "significant weather: NIL (none), RASH (rain), TS (thunder storm)",
      "RR": "total precipitation [mm]",
      "RR-str": "stratiform precipitation",
      "CONV": "convective precipitation [mm]",
      "SN": "precipitation in form of snow [mm]",
      "SN--RA": "snowline [m]",
      "QAO ": "{{value}} hPa eq. mean wind, direction and strength, [°] and [m/s]",
      "QAN": "mean wind on the ground, direction and strength, [°] and [m/s]",
      "QANmax": "max. wind (gusts) on the ground [m/s]",
      "T ": "{{value}} hPa eq. temperature [°C]",
      "T m": "temperature on {{value}}m [°C]",
      "T 2m": "temperature on 2m [°C]",
      "Tk 2m": "temperature on 2m [°K]",
      "T GND": "ground temperature",
      "Max-ADI": "maximum mixing temperature [°C]",
      "Min Max": "min./max. temperature in the last 24h",
      "FROST": "zero degree line [m]",
      "W cm": "500 hPa eq. vertical wind [cm/s]",
      "W --": "700 hPa eq. vertical wind [cm/s]",
      "W s": "{{value}} hPa eq. vertical wind [cm/s]",
      "H ": "{{value}} hPa geopotential height [m]",
      "RF ": "{{value}} hPa eq. relative moisture [%]"
    }
  }
}
